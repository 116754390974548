@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  h1, h2, h3, h4, h5, h6 {
    @apply font-heading my-6 font-bold;
  }

  h1 {
    @apply text-4xl
  }
  
  h2 {
    @apply text-3xl
  }

  h3 {
    @apply text-2xl
  }

  h4 {
    @apply text-xl
  }

  h5 {
    @apply text-lg
  }

  ul, ol {
    @apply my-4
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    @apply my-2
  }
}

@layer components {
  .btn {
    @apply disabled:bg-sky-500 disabled:cursor-not-allowed hover:no-underline hover:text-white bg-sky-500 tracking-wider w-full text-center text-white font-bold cursor-pointer uppercase px-4 py-3 my-2 rounded-md hover:bg-sky-600 transition-colors block;
  }

  .btn-landing {
    @apply disabled:bg-sky-500 disabled:cursor-not-allowed hover:no-underline hover:text-white bg-sky-500 tracking-wider w-full text-center text-white font-bold cursor-pointer px-4 py-3 my-2 rounded-lg hover:bg-sky-600 transition-colors;
  }
}

@layer utilities {
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);;
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  .animate-bounce-once {
    animation: bounce 1s;
  }
}